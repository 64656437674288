<template>
    <div class="Coupon">
        <div class="item-header ">
            <div class="vertical-blue-line-left">七田真产品</div>
            <div style="display: flex;justify-content: space-around" class="flex">
                <div style="width: 250px;" class="border border-box border-radius-5 padding-10" v-for="(goods,index) in onSaleGoodsList" key="index">
                    <a-row>
                        <a-col :span="6" style="height: 100px;line-height: 100px"> <a-avatar :size="64" icon="user" :src="goods.thumb" shape="square"  /></a-col>
                        <a-col :span="16">
                            <p>{{goods.title}}</p>
                            <p>{{goods.price}}元</p>
                            <p>{{goods.age}}岁</p>
                        </a-col>
                    </a-row>
                </div>

            </div>
            <div class="screen-list-item">
                <a-button style="margin-right:20px" @click="resetBtn()">重置</a-button>
                <a-button type="primary" @click="serachBtn()">查询</a-button>
            </div>

            <div>
                <a-button type="primary" @click="onTtableClick" ><a-icon type="plus" />新建兑换码</a-button>
            </div>
        </div>

        <div class="coupon-content" :style="{'min-height':minHeight}">
            <div style="margin-top:15px;">
                <a-table :columns="columns" :dataSource="infoData" :pagination="false" bordered>

                    <div slot="action" slot-scope="record">
                        <a-popover placement="bottom" v-if="record.code_package_state != 4">
                            <template slot="content" >
                                <div class="edit-item">
                                    <a href="#" @click="_showEdit(record.code_package_id,false)">编辑</a>
                                </div>
                                <div class="edit-item">
                                    <a href="#" @click="_updateCodePackageState(record,4)">停止</a>
                                </div>
                                <div class="edit-item" v-if="record.code_package_state == 1">
                                    <a href="#" @click="_updateCodePackageState(record,4)">开始</a>
                                </div>
                            </template>
                            <a-icon type="dash" class="smalldash" @click.stop/>
                        </a-popover>
                        <div class="edit-item" v-else>
                            <a href="#" @click="_showEdit(record.code_package_id,true)">查看</a>
                        </div>
                    </div>

                    <div slot="user" slot-scope="record">
                        <span  class="link-text">{{record.name}}</span>
                    </div>
                </a-table>
            </div>
            <div style="margin: 20px 0;text-align: right;" v-if="total > 0">
                <a-pagination :defaultCurrent="1" :total="total" @change="pageChange" />
            </div>
        </div>


    </div>
</template>
<script>
import moment from 'moment';
export default {
    name:'ProductStudentSale',
    components: {

    },
    data(){
        return{
            columns: [
                { title: '客户名称', dataIndex: 'user', key: 'user',align:'center',width:300,scopedSlots: { customRender: 'user' }, },
                { title: '联系电话', dataIndex: 'userPhone', key: 'userPhone',align:'center',width:120,scopedSlots: { customRender: 'userPhone' }, },
                { title: '生日', dataIndex: 'birthday', key: 'birthday',align:'center',width:300 ,scopedSlots: { customRender: 'birthday' },},
                { title: '年龄', dataIndex: 'age', key: 'age',align:'center',width:130 ,scopedSlots: { customRender: 'age' },},
                { title: '购买', dataIndex: 'bought', key: 'bought',align:'center',width:100,scopedSlots: { customRender: 'bought' },},
                { title: '家长态度', dataIndex: 'boughtIntend', key: 'boughtIntend',align:'center',width:100,scopedSlots: { customRender: 'boughtIntend' }, },
                { title: '备注', dataIndex: 'remark', key: 'remark',align:'center',width: 300, scopedSlots: { customRender: 'remark' }, },
                { title: '操作栏', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' },align:'center',width: 120 },
            ],





            infoData:[{
                user:{
                    name:'汪乐',
                    gender:1
                },
            }],

            onSaleGoodsList:[
                {thumb:"",title:"七田真游戏纸牌",age:2,price:800.00},
                {thumb:"",title:"七田真游戏纸牌",age:2,price:800.00},
                {thumb:"",title:"七田真游戏纸牌",age:2,price:800.00},
                {thumb:"",title:"七田真游戏纸牌",age:2,price:800.00},
                {thumb:"",title:"七田真游戏纸牌",age:2,price:800.00},
                {thumb:"",title:"七田真游戏纸牌",age:2,price:800.00},

            ],



            showCodeListState:false,
            codeListTitle:'',
            codeListSerachParams:{
                codePackageId:'',
                codeName:'',
                codeState:0,
                page:1,
            },
            codeListTotal:0,
            codeListPage:1,
        }
    },
    created(){
    },
    mounted(){

    },
    methods:{
        moment,
        serachBtn(){
            this.page = 1;

        },
        onTtableClick(){
            this.ExChangeVisible = true;
            this._init_codeCatogories();
            this._get_tabs_data(3);
            this._getSendChannel();
        },
        resetBtn(){
            this.serachParams = {
                codePackageName: '',
                codePackageState: '0',
                courseId: '',
                exchangeType: '0',
                expireTime: '',
                startTime: '',
            }
            this.page = 1;
        },
        changeTime(value){
            if (value.length > 0) {
                this.serachParams.startTime = moment(value[0]._d).format('YYYY-MM-DD');
                this.serachParams.expireTime = moment(value[1]._d).format('YYYY-MM-DD');
            } else {
                this.serachParams.startTime = '';
                this.serachParams.expireTime = '';
            }
        },
        pageChange(pageNumber){
            this.page = pageNumber;
            this._getData();
        },

    },
}
</script>

<style lang="less" >
@import "/src/styles/theme/index.less";

.Coupon{
    .item-header{
        padding: 15px 20px;
        background: #fff;
        border-radius: 4px;
        .screen-list-item{
            margin-right: 20px;
        }
        .screen-item-label{
            margin-right: 10px;
            color: #666;
            font-size: 14px;
            white-space: nowrap;
        }
    }
    .coupon-content{
        padding: 15px;
        background: #fff;
        margin-top: 20px;
        border-radius: 4px;
        .content-title{
            span{
                font-size: 18px;
                color: #333;
            }
            i{
                margin-left: 10px;
                font-size: 18px;
                cursor: pointer;
            }
        }

        .smalldash{
            line-height: 30px;
        }
        .edit-item{
            font-size:12px;
        }
    }
}
.Coupon .ant-select-selection--single,.Coupon .ant-input{height: 36px;}
.Coupon .ant-select-selection__rendered{line-height: 34px;}
.Coupon .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 16px 6px;
}
.coupon_form{padding-bottom: 30px;}
.coupon_form .ant-row .ant-form-item-label{text-align: left;}
.coupon_form .ant-radio-group .ant-input-number{width: 124px;}
.coupon_form .ant-input-number{width:330px;}




</style>